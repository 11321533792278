import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const OnlineLiveAppointment: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="01-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{
            backgroundImage:
              'url(../../img/lon-online-live-appointment-header.jpg)',
          }}
        ></div>
        <article className="post">
          <h1>Online Live Appointment</h1>

          <div className="row">
            <div className="col-md-5 block-title py-2">
              <h2>
                Be like in your Longines store through an online video meeting
              </h2>
            </div>
            <div className="col-md-7">
              <LightboxWrapper>
                <a href={'../../img/lon-online-live-appointment.jpg'}>
                  <img
                    src={'../../img/lon-online-live-appointment.jpg'}
                    className="img-fluid thumbnail"
                  />
                </a>
              </LightboxWrapper>
            </div>
          </div>
          <div className="spacer"></div>
          <h3>How it works ?</h3>
          <p>
            OLA is a new feature that allows customers to benefit from a
            personalized product presentation through an online video meeting
            like if they were physically in a Longines boutique.
          </p>
          <ol>
            <li>
              Customer select 1 to 3 watches through the website, fill in the
              form and send the appointment request
            </li>
            <li>POS contact the customer to confirm the appointment</li>
            <li>D-Day – Online Live Appointment</li>
            <li>POS send thank you mail with the product links</li>
          </ol>
          <div className="spacer"></div>
          <h3>Why ?</h3>
          <ul>
            <li>Anticipate consumers’ change of behaviour due to Covid-19,</li>
            <li>Improve Longines omnichannel’s strategy,</li>
            <li>Strengthen our position our brand as a digital brand,</li>
            <li>Provide a new premium service to our customer.</li>
          </ul>
          <div className="spacer"></div>
          <div className="row">
            <div className="col-md-7">
              <p>
                The French market has already added this feature to their
                strategy. You can see the final result in store here and on the
                website by clicking here.
              </p>
              <p>
                On the below link, you will find additional documentation and
                the work needed to apply for this new feature.
              </p>
              <a
                href="https://itsazrmgrswatchgroup-my.sharepoint.com/:f:/g/personal/lon-roddan_swatchgroup_net/Es3JaWIGdMZOhDqR7rlp4W0Bz_eQk4455_tEqXJ9KhnUjw?e=VpkzUl"
                target="_blank"
                rel="noopener"
                className="btn btn-primary"
              >
                Download Documents
              </a>
              <div className="spacer"></div>
            </div>

            <div className="col-md-5 block-title py-2">
              <h2>32 appointments for 17 watches sold since December 2020</h2>
            </div>
          </div>
          <div className="spacer"></div>

          <h3>Prerequisites</h3>
          <ul className="checkbox">
            <li>
              <ion-icon name="checkbox-outline" className="mr-3"></ion-icon>{' '}
              Having a website with your own domain
            </li>
            <li>
              <ion-icon name="checkbox-outline" className="mr-3"></ion-icon>{' '}
              Having an Official Longines Boutique
            </li>
            <li>
              <ion-icon name="checkbox-outline" className="mr-3"></ion-icon> IT
              material
            </li>
            <li>
              <ion-icon name="checkbox-outline" className="mr-3"></ion-icon>{' '}
              Content translation
            </li>
            <li>
              <ion-icon name="checkbox-outline" className="mr-3"></ion-icon>{' '}
              Terms & Conditions review and adaptation
            </li>
            <li>
              <ion-icon name="checkbox-outline" className="mr-3"></ion-icon>{' '}
              Data privacy review and adaptation
            </li>
            <li>
              <ion-icon name="checkbox-outline" className="mr-3"></ion-icon>{' '}
              Product availability in the right place
            </li>
            <li>
              <ion-icon name="checkbox-outline" className="mr-3"></ion-icon>{' '}
              Customer management
            </li>
            <li>
              <ion-icon name="checkbox-outline" className="mr-3"></ion-icon>{' '}
              Communication plan
            </li>
          </ul>

          <blockquote>
            <div className="d-flex">
              <img
                src={'../../img/dani-heitor.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:dani.heitor@longines.com">
                    dani.heitor@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default OnlineLiveAppointment

export const pageQuery = graphql`
  query OnlineLiveAppointmentQuery_01_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
