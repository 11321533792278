import React from 'react'
import { SRLWrapper } from 'simple-react-lightbox';

interface Props {
  children?: React.ReactNode
  options?: Record<string, unknown>
}

const LightboxWrapper: React.FC<Props> = ({ children, options }: Props) => {
  const defaultOptions = {
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  }

  return (
    <SRLWrapper options={{ ...defaultOptions, ...options }}>
      {children}
    </SRLWrapper>
  )
}

export default LightboxWrapper
